import { Flex, Image, Text } from "@chakra-ui/react";
import Logo from "../../assets/logo.svg";
import DesktopLogo from "../../assets/desktop-logo.svg";
import Menu from "../../assets/menu.svg";
import PrimaryButton from "../primaryButton/primarybutton";
import { HiChevronDown } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDrawer from "../customDrawer/customDrawer";
import { useEffect, useState } from "react";

const navData = [
  {
    title: "Get Started",
    url: "https://app.gameporte.com/",
    subMenu: [],
    type: "ext",
  },
  {
    title: "Become a Community Manager",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSc9Mj5kOBJ-gLzKFd9Gf2YVjilEp3yrhfHFs7zLu_-GbGMcvA/viewform",
    subMenu: [],
    type: "ext",
  },
];

const NavBar = () => {
  const navigate = useNavigate();
  const [triggerClose, setTriggerClose] = useState("");
  const { pathname } = useLocation();

  const val = pathname === "/" ? 90 : 40;

  const [bg, setBg] = useState("transparent");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkScroll = () => {
    // Add an event listener to the window object for the 'scroll' event
    window.addEventListener("scroll", () => {
      // Check if the scroll position is greater than 0
      if (window.scrollY > val) {
        setBg("#000");
      } else {
        setBg("transparent");
      }
    });
  };

  //   const handleDrawerMenu = (url) => {
  //     navigate(`/${url}`)
  //     setTriggerClose(Math.random()?.toString() + 'close')
  //   }

  useEffect(() => {
    checkScroll();
  }, [checkScroll]);

  return (
    <Flex
      height={["80px", "112px"]}
      transition={"250ms ease"}
      bg={bg}
      pt={["0", "0", "2rem"]}
      justifyContent={"center"}
      position={"fixed"}
      zIndex={"100"}
      width="100%"
    >
      <Flex
        display={["none", "none", "flex", "flex"]}
        borderRadius={"100px"}
        height={"70px"}
        width="95%"
        boxShadow={"0px 0px 100px 0px #0000000F"}
        // bg="linear-gradient(180deg, rgba(230, 230, 230, 0.16) 0%, rgba(179, 179, 179, 0.16) 100%)"
        bg="#fff"
        justifyContent={"space-between"}
        alignItems={"center"}
        px={["10px", "1rem", "1rem", "1.5rem"]}
      >
        <Image
          display={["none", "block"]}
          cursor={"pointer"}
          onClick={() => navigate("/")}
          transform={"scale(.8)"}
          src={DesktopLogo}
        />
        <Image
          display={["block", "none"]}
          cursor={"pointer"}
          onClick={() => navigate("/")}
          transform={"scale(.8)"}
          src={Logo}
        />

        <Flex alignItems={"center"} gap={"2rem"}>
          <Flex
            justifyContent={"space-between"}
            gap={"2rem"}
            alignItems={"center"}
          >
            {navData?.map(({ title, subMenu, url }) => {
              return (
                <a key={title} href={url} target="_blank" rel="noreferrer">
                  <Text
                    // onClick={() => handleDrawerMenu(url)}
                    whiteSpace={"nowrap"}
                    cursor={"pointer"}
                    fontWeight={"600"}
                    fontSize={".85rem"}
                    color={"#0071FE"}
                  >
                    {title}{" "}
                    {subMenu?.length > 0 && (
                      <HiChevronDown
                        style={{
                          color: "#0071FE",
                          display: "inline",
                          transform: "translateY(3px)",
                        }}
                      />
                    )}
                  </Text>
                </a>
              );
            })}
          </Flex>

          <a href="https://shop.gameporte.com" target="_blank" rel="noreferrer">
            <PrimaryButton
              bg="#0071FE"
              icon="shop"
              py="1rem"
              width="150px"
              height="50px !important"
              fontWeight="700 !important"
              color="#fff !important"
              text="Porte Shop"
            />
          </a>
        </Flex>
      </Flex>

      <Flex
        display={["flex", "flex", "none", "none"]}
        position={"fixed"}
        zIndex={"100"}
        width="100%"
        height={"75px"}
        borderBottom={".5px solid #1D2939"}
        boxShadow={"0px 2px 4px -1px #10192805"}
        bg="#252525"
        justifyContent={"space-between"}
        alignItems={"center"}
        px={["1.3rem", "1rem", "2rem", "4rem"]}
      >
        <Image
          cursor={"pointer"}
          onClick={() => navigate("/")}
          ml="-1.5rem"
          transform={"scale(.8)"}
          src={Logo}
        />

        <CustomDrawer
          position={"top"}
          title={
            <Image
              transform={"translateX(-1rem) scale(.8)"}
              cursor={"pointer"}
              src={Logo}
            />
          }
          triggerClose={triggerClose}
          setTriggerClose={setTriggerClose}
          bg="#252525"
          closeButtonColor="#FFF"
          toggleElement={
            <Image
              transform={"scale(1) translateX(.5rem)"}
              cursor={"pointer"}
              src={Menu}
            />
          }
          content={
            <>
              <Flex
                direction={"column"}
                justifyContent={"space-between"}
                gap={"2rem"}
                alignItems={"center"}
                py="2rem"
                mb="2rem"
                borderTop="1px solid #344054"
                borderBottom="1px solid #344054"
              >
                {navData?.map(({ title, subMenu, url }) => {
                  return (
                    <a key={title} href={url} target="_blank" rel="noreferrer">
                      <Text
                        whiteSpace={"nowrap"}
                        cursor={"pointer"}
                        fontWeight={"600"}
                        fontSize={".95rem"}
                        color={"#FFF"}
                      >
                        {title}{" "}
                        {subMenu?.length > 0 && (
                          <HiChevronDown
                            style={{
                              color: "#FFF",
                              display: "inline",
                              transform: "translateY(3px)",
                            }}
                          />
                        )}
                      </Text>
                    </a>
                  );
                })}
              </Flex>

              <Flex direction={"column"} alignItems={"center"} gap={"1.2rem"}>
                <Flex
                  display={["none", "none", "flex", "flex"]}
                  justifyContent={"space-between"}
                  gap={"2rem"}
                  alignItems={"center"}
                >
                  {navData?.map(({ title, subMenu, url }) => {
                    return (
                      <a
                        key={title}
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text
                          onClick={() => navigate(`/${url}`)}
                          whiteSpace={"nowrap"}
                          cursor={"pointer"}
                          fontWeight={"600"}
                          fontSize={[".75rem", ".85rem"]}
                          color={"#FFF"}
                        >
                          {title}{" "}
                          {subMenu?.length > 0 && (
                            <HiChevronDown
                              style={{
                                color: "#FFF",
                                display: "inline",
                                transform: "translateY(3px)",
                              }}
                            />
                          )}
                        </Text>
                      </a>
                    );
                  })}
                </Flex>

                <a
                  href="https://shop.gameporte.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton
                    bg="#FFF"
                    icon="shop"
                    py="1rem"
                    width="180px"
                    height="50px !important"
                    color="#0071FE !important"
                    text="Porte Shop"
                  />
                </a>
              </Flex>
              <br />
              <br />
            </>
          }
        />
      </Flex>
    </Flex>
  );
};

export default NavBar;
