import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  Link
} from '@chakra-ui/react';
import Bg from './bg.svg';

const data = [
  {
    question: 'What is Gameporte?',
    answer: (
      <Text>
        Gameporte is a fun, AI-powered social platform that brings gamers together! Our platform offers you a place to compete in tournaments, connect with friends, buy game-related items, and win cool rewards—all in one place.
      </Text>
    ),
  },
  {
    question: 'How does Gameporte work?',
    answer: (
      <Text>
        This is super easy! Sign up, top up your wallet, and dive into the action; you can join tournaments, challenge your friends, or team up with others. Gameporte automatically handles tournament setup, matchmaking, and automatic prize distribution. Plus, you can shop for game vouchers and merchandise right in the app!
      </Text>
    ),
  },
  {
    question: 'What features does Gameporte offer?',
    answer: (
      <UnorderedList>
      <Text>
      Gameporte has everything a gamer needs:
      </Text>
        <ListItem>AI-powered tournaments that are set up automatically</ListItem>
        <ListItem>1v1 challenges or team matches</ListItem>
        <ListItem>Gamer profiles, chat rooms, and activity feeds to stay connected</ListItem>
        <ListItem>A built-in wallet for easy transactions and rewards</ListItem>
        <ListItem>An e-commerce store for game-related products and gift cards</ListItem>
      </UnorderedList>
    ),
  },
  {
    question: 'Who can use Gameporte?',
    answer: (
      <Text>
        Gameporte is for everyone—the casual gamer, esports fan, or member of a gaming community. Gameporte is perfect for anyone who wants to play for fun or even make money with their gaming skills.
      </Text>
    ),
  },
  {
    question: 'How do I get started on Gameporte?',
    answer: (
      <Text>
        To get started on Gameporte, download the app from the Google or iOS stores, sign up, set up your preferred profile, and you can start playing in tournaments, challenging friends, or shopping in the store, right away.
      </Text>
    ),
  },
  {
    question: 'How do I earn rewards on Gameporte?',
    answer: (
      <Text>
        You can earn rewards by winning from prize pools in tournaments, by knocking out your friends in 1v1 matches, and by participating in the community. Your rewards will go directly to your wallet, and you can easily withdraw to your bank account whenever you want.
      </Text>
    ),
  },
  {
    question: 'How secure is the Gameporte platform?',
    answer: (
      <Text>
        Your personal data and transactions are protected by high-security measures on the Gameporte platform. We use KYC (Know Your Customer) verification to provide an added level of account security, in addition to our wallet being encrypted.
      </Text>
    ),
  },
  {
    question: 'What types of tournaments can I join on Gameporte?',
    answer: (
      <Text>
        You can join any tournament on Gameporte, including 1v1 matchups, team competitions, and even big competitions with cash prizes. We are always adding new competitions in various games and categories.
      </Text>
    ),
  },
  {
    question: 'What games are supported on Gameporte?',
    answer: (
      <Text>
        Gameporte supports a wide variety of popular games across multiple platforms. We regularly add new games based on what our community wants to play.
      </Text>
    ),
  },
  {
    question: 'How do I purchase items from the Gameporte e-commerce store?',
    answer: (
      <Text>
        Shopping on Gameporte is simple! Simply log in to the Porteshop, our e-commerce store, using the Gameporte app or the web, fund your wallet, browse the store, and buy any game vouchers, gift cards, or gaming accessories you like.
      </Text>
    ),
  },
  {
    question: 'How can I join or create a gaming community on Gameporte?',
    answer: (
      <Text>
        You can join or create your own community directly in the app. Use our social features to chat, organize tournaments, and team up with friends.
      </Text>
    ),
  },
  {
    question: 'How can businesses collaborate with Gameporte?',
    answer: (
      <Text>
        We love working with businesses! Gameporte offers opportunities for sponsorships, advertising, and branded content to a highly engaged audience. If you're interested, email us at <Link href="mailto:hello@gameporte.com">hello@gameporte.com</Link>.
      </Text>
    ),
  },
  {
    question: 'How do I contact Gameporte for support?',
    answer: (
      <Text>
        You can reach out to our support team on the app or by emailing us at <Link href="mailto:hello@gameporte.com">hello@gameporte.com</Link>. We're always glad to help!
      </Text>
    ),
  },
];


const Faq = () => {
  return (
    <Box
      px={['1rem', '3rem', '6rem']}
      bg="#000000"
      pt={['5rem', '7rem']}
      pb="4rem"
      position={'relative'}
    >
      <Box
        flex={'1'}
        maxW={'550px'}
        mt="2rem"
        zIndex={'6 !important'}
        textAlign={'left'}
      >
        <Heading
          fontWeight={'400'}
          fontFamily={'poster'}
          letterSpacing={'1px'}
          display={'inline'}
          className="gradient-text"
          lineHeight={['60px', '45px', '57px', '70px']}
          fontSize={['4rem', '6rem']}
        >
          FREQUENTLY ASKED QUESTIONS
        </Heading>
        <Divider mt="1.5rem" />
        <Accordion
          mt="1.5rem"
          allowToggle
          allowMultiple={false}
          defaultIndex={[0]}
        >
          {data?.map(({ question, answer }, index) => {
            return (
              <AccordionItem
                key={index}
                borderColor={'#E4E7EC'}
                border="none"
                borderBottom=".1px solid #D9DBE9"
              >
                <h2>
                  <AccordionButton padding={'.6rem 0'}>
                    <Box
                      color={'#E4E7EC'}
                      fontWeight="400"
                      fontSize={['.97rem', '1.1rem']}
                      padding={'.6rem 0'}
                      as="span"
                      flex="1"
                      textAlign="left"
                      borderTop={'none'}
                      _expanded={{ fontWeight: '500' }}
                      fontFamily={'Open Sans'}
                    >
                      {question}
                    </Box>
                    <AccordionIcon color={'#E4E7EC'} />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  color={'#D0D5DD'}
                  fontSize={['.85rem', '.95rem']}
                  pb={4}
                  padding={'.7rem 0 1.3rem 0'}
                  maxW="90%"
                  textAlign={'left'}
                >
                   {answer}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
      <Box
        width={'900px'}
        height={'560px'}
        backgroundImage={Bg}
        backgroundPosition={'right'}
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        position={'absolute'}
        top="24%"
        right={'0'}
        zIndex={'1 !important'}
        display={['none', 'block']}
      />
    </Box>
  );
};

export default Faq;
