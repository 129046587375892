import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Bg1 from "./assets/bg1.svg";
import Wallet from "./assets/wallet.svg";
import Team from "./assets/team.svg";
import Wall from "./assets/wall.svg";
import Chat from "./assets/chat.svg";
import Check from "./assets/check.svg";
import CommunityIcon from "./assets/community_icon.svg";

const Features = () => {
  const datax = [
    {
      icon: Wallet,
      text: "Organize tournaments",
    },
    {
      icon: Wall,
      text: "Perform gaming transactions",
    },
    {
      icon: Chat,
      text: "Connect via chats and channels",
    },
    {
      icon: CommunityIcon,
      text: "Build communities",
    },
    {
      icon: Team,
      text: "Rank up and claim glory",
    },
  ];

  return (
    <Box
      data-aos="fade-up"
      bg="#000"
      pt={["3rem", "6rem"]}
      pb={["2rem", "8rem"]}
      position={"relative"}
      px={[".5rem", "5rem"]}
      overflow={"hidden"}
    >
      <Flex
        position={"relative"}
        flexWrap={"wrap"}
        flexDirection={["column", "row"]}
        gap={["4rem", "10rem"]}
      >
        <Box flex={"1"}>
          <Image transform={["translateX(-2.6rem) scale(1.2)", ""]} src={Bg1} />
        </Box>

        <Box
          zIndex={"2"}
          height={"max-content"}
          flex={"1"}
          borderRadius={"20px"}
          px="1rem"
          textAlign={["center", "left"]}
        >
          {" "}
          {datax?.map(({ icon, text }, index) => (
            <Flex
              key={text}
              justifyContent={"space-between"}
              mb="2rem"
              border="2px solid #FFF"
              boxShadow={"0 0 10px #FFF"}
              borderRadius={"100px"}
              p={[".4rem 1rem", ".6rem 1.2rem"]}
            >
              <Flex alignItems={"center"} gap=".8rem">
                <Image src={icon} transform={["scale(.45)", "scale(.65)"]} />
                <Text
                  fontSize={["1.1rem", "1.3rem"]}
                  textAlign={"left"}
                  fontWeight={"500"}
                  color={"#FFF"}
                >
                  {text}
                </Text>
              </Flex>
              <Image src={Check} transform={["scale(.45)", "scale(.65)"]} />
            </Flex>
          ))}
        </Box>
        {/* <Box zIndex={"2"} flex={"1"} height={"600px"} minWidth={"350px"} backgroundImage={Bg1} backgroundSize={"contain"} /> */}
      </Flex>
    </Box>
  );
};

export default Features;
