import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/footer';
import NavBar from './components/navbar/navBar';
import LandingPage from './pages/landingPage/landingPage';
import ContactUs from './pages/contactUs/contactUs';
import { useEffect } from 'react';
import Privacy from './pages/privacy/privacy';
import Faq from './pages/landingPage/components/faq/faq';
import About from './pages/landingPage/components/about/about';
import 'aos/dist/aos.css';
import Aos from 'aos';
import Terms from './pages/Terms/terms';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
