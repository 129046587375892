import Banner from "./components/banner/banner";
import Features from "./components/features/features";
import Hero from "./components/hero/hero";
import Partners from "./components/partners/partners";
import Download from "./components/download/download";

const LandingPage = () => {
  return (
    <>
      <Hero />
      {/* <Banner /> */}
      <Features />
      <Partners />
      <Download />
    </>
  );
};

export default LandingPage;
