import { Box, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import PrimaryButton from '../../components/primaryButton/primarybutton';
import CustomInput from '../../components/customInput/customInput';
import CustomTextArea from '../../components/customTextArea/customTextArea';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';
import Bg from './bg.svg';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const contact = async (e) => {
    e.preventDefault();
  };

  const links = [
    {
      name: '+234 803 493 7754',
      url: 'tel:+234 803 493 7754',
      icon: (
        <AiOutlinePhone
          style={{ display: 'inline', transform: 'translateY(2px)' }}
        />
      ),
    },
    {
      name: 'hello@gameporte.com',
      url: 'mailto:hello@gameporte.com?subject=Gameporte Support Email',
      icon: (
        <AiOutlineMail
          style={{ display: 'inline', transform: 'translateY(2px)' }}
        />
      ),
    },
    {
      name: '256 Chapman Road STE105-4, Newark, Delaware',
      url: '#',
      icon: (
        <BiMapPin style={{ display: 'inline', transform: 'translateY(2px)' }} />
      ),
    },
  ];

  return (
    <Box
      px={['1rem', '3rem', '6rem']}
      bg="#000000"
      pt={['5rem', '7rem']}
      pb="10rem"
      position={'relative'}
    >
      <Box
        flex={'1'}
        maxW={'550px'}
        mt="2rem"
        zIndex={'6 !important'}
        textAlign={'left'}
      >
        <Heading
          fontWeight={'400'}
          fontFamily={'poster'}
          letterSpacing={'1px'}
          display={'inline'}
          className="gradient-text"
          lineHeight={['60px', '45px', '57px', '70px']}
          fontSize={['4rem', '6rem']}
        >
          CONTACT US
        </Heading>
        <Box mt="1rem">
          {links?.map(({ name, url, icon }) => (
            <Text
              key={name}
              onClick={() => (window.location.href = url)}
              fontSize={'.87rem'}
              transition={'250ms ease'}
              _hover={{ color: '#02ACDF' }}
              fontWeight={'500'}
              cursor={'pointer'}
              mb="1.2rem"
              color="#FCFCFD"
            >
              {icon} {name}
            </Text>
          ))}
        </Box>

        <form onSubmit={contact}>
          <CustomInput
            placeholder={'Enter full name here'}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            bg="transparent"
            color="#FFF"
          />

          <CustomInput
            placeholder={'Enter email address here'}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value?.toLowerCase())}
            bg="transparent"
            color="#FFF"
          />

          <CustomTextArea
            placeholder={'Enter your message here'}
            value={message}
            rows="4"
            onChange={(e) => setMessage(e.target.value)}
            bg="transparent"
            color="#FFF"
          />

          <a
            href={`mailto:support@gameporte.com?subject=Gameporte Support Email from ${name} - ${email}&body=${message}`}
          >
            <PrimaryButton
              mt="3rem"
              bg="#0071FE"
              borderRadius="24px 0px 24px 0px"
              text="Send a message"
            />
          </a>
        </form>
      </Box>
      <Box
        width={'600px'}
        height={'560px'}
        backgroundImage={Bg}
        backgroundPosition={'right'}
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        position={'absolute'}
        top="24%"
        right={'0'}
        zIndex={'4 !important'}
        display={['none', 'block']}
        transform={'rotate(-45deg)'}
      />
    </Box>
  );
};

export default ContactUs;
