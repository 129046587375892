import { Box, Heading, Image } from '@chakra-ui/react';
import Nb from './assets/nb.svg'
import Zagg from './assets/zagg.svg'
import Anc from './assets/anchor.svg'
import Live from './assets/livespot.svg'
import Cru from './assets/crusifix.svg'
import Marquee from 'react-fast-marquee';
import Drone from './assets/drone.svg'
import './anim.css' 

const Partners = () => {
  const logos = [Nb, Zagg, Anc, Live, Cru]
  return (
    <Box data-aos="fade-up" py="3rem" bg="#000" position={"relative"}>
        <Image src={Drone} opacity={["0", "1"]} className='drone' position={"absolute"} />
        <Box textAlign={"center"} mb="2.5rem">
          <Heading fontWeight={"400"} fontFamily={"poster"} whiteSpace={"nowrap"} letterSpacing={"1px"} display={"inline"} className="gradient-text" lineHeight={["60px", "45px", "57px",  "70px"]} fontSize={["4rem", "8.1rem"]}>OUR PARTNERS</Heading>
        </Box>
        <Marquee autoFill={true} pauseOnHover style={{justifyContent: "center", alignItems: "center", display: "flex", width: "100%"}}>
            {logos.map((logo, index) => (
            <div key={index} style={{display: "flex"}}>
                <Image alt={`Logo ${index}`} src={logo} verticalAlign={"middle"} alignSelf={"center"} mx={["", "2rem"]} transform={["scale(.6)", "scale(.7)"]} />
            </div>
            ))}
            
        </Marquee>

        {/* <video style={{width: "100%", height: "100%"}} controls autoPlay muted loop src={"https://res.cloudinary.com/giftguy/video/upload/v1713732517/gpvid_odxhvf.mp4"}></video> */}
    </Box>
  )
}

export default Partners;