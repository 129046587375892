/* eslint-disable react/prop-types */
import { Button, Image, Spinner, Text } from "@chakra-ui/react";
import Play from "../../pages/landingPage/components/hero/assets/play.svg";
import Web from "../../pages/landingPage/components/hero/assets/web.svg";
import PlayStore from "../../pages/landingPage/components/hero/assets/playstore.svg";
import Apple from "../../pages/landingPage/components/hero/assets/apple.svg";
import Download from "../../assets/download.svg";
import Shop from "../../assets/shop.svg";

const PrimaryButton = (props) => {
  return (
    <Button
      backgroundColor={props?.bg || "#101828"}
      fontSize=".87rem"
      fontWeight={"500"}
      fontFamily={"Inter"}
      borderRadius={"24px 0px 24px 0px"}
      padding={["1.65rem 1.5rem", "1.65rem 1.5rem"]}
      width={"100%"}
      _hover={{ color: "#000" }}
      transition={"250ms ease"}
      _disabled={{ backgroundColor: "#D0D5DD", cursor: "not-allowed" }}
      {...props}
    >
      {props.loading === true ? (
        <Spinner color="#FFF" />
      ) : (
        <>
          {props.icon === "play" && (
            <Image transform={"scale(.7) translateX(-.7rem)"} src={Play} />
          )}
          {props.icon === "web" && (
            <Image transform={"scale(.7) translateX(-.7rem)"} src={Web} />
          )}
          {props.icon === "download" && (
            <Image transform={"translateX(-.7rem)"} src={Download} />
          )}
          {props.icon === "playstore" && (
            <Image transform={"translateX(-.7rem)"} src={PlayStore} />
          )}
          {props.icon === "apple" && (
            <Image transform={"translateX(-.7rem)"} src={Apple} />
          )}
          <Text
            fontSize={props?.icon === "download" ? ".95rem" : ".85rem"}
            color={props?.color || "#FFFFFF"}
          >
            {props?.children ? props.children : props.text}
          </Text>
          {props.icon === "shop" && (
            <Image transform={"scale(.8) translateX(.7rem)"} src={Shop} />
          )}
        </>
      )}
    </Button>
  );
};

export default PrimaryButton;
